import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Epistemic = makeShortcode("Epistemic");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Epistemic effort mdxType="Epistemic">
  Low-to-medium effort. It isn't my best work, but I hope it shows that
  contributing to DefinitelyTyped isn't scary. I might refine it into a
  meetup talk or a tutorial someday.
    </Epistemic>
    <p>{`18:37`}</p>
    <p>{`I’m using both theme-ui and chakra-ui in an app, and it just doesn’t work.
Don’t get me wrong, they’re both really good libraries,
and they’re both using @emotion/core to provide the dynamic styling API that IMHO
encourages good composition and makes styling faster.`}</p>
    <p>{`You just shouldn’t use both at once because they step on each other feet.
I’d probably go with Chakra, because this is an app, not a blog or a landing page,
and I expect I’ll need most of the components from it.
However, we’ve decided that theming is a must-have feature of our app.`}</p>
    <p>{`I’m going to swap Chakra with `}<inlineCode parentName="p">{`@theme-ui/components`}</inlineCode>{`, to get the bundle size lower and
satisfy my theming needs. The problem is, this package has no typings. Jxnblk builds
great stuff for styling the modern web, but I just can’t use any library without types.
It’s not you, it’s me, and I need to do something about it.`}</p>
    <p>{`18:45`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "sh"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`git clone --depth 1 --branch master git@github.com:DefinitelyTyped/DefinitelyTyped.git`}</span></span></code></pre>
    <p>{`Well, here we go again. I don’t want to do it. I don’t have time to do it.
Yet, it is a noble thing to do, and someone has to.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`> npx dts-gen --dt --name @theme-ui/components --template module`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`npx: installed 59 in 6.878s`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Unexpected crash! Please log a bug with the commandline you specified.`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ENOENT: no such file or directory, mkdir 'types\\@theme-ui\\components'`}</span></code></pre>
    <p>{`Well, obviously. Since `}<inlineCode parentName="p">{`@theme-ui/components`}</inlineCode>{` is in `}<em parentName="p">{`theme-ui`}</em>{` namespace and
we can’t have a directory with a slash in name, we need to stick to the conventional workaround.`}</p>
    <sup>It doesn't crash anymore in January 2020.</sup>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`> npx dts-gen --dt --name theme-ui__components --template module -o`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`npx: installed 59 in 7.202s`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Warning: Could not retrieve version/homepage information: HTTP Error 404: Not Found for http://registry.npmjs.org/theme-ui__components`}</span></code></pre>
    <p>{`Oh right. It worked, though.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`> ls ./types/theme-ui__components`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`index.d.ts  theme-ui__components-tests.ts  tsconfig.json  tslint.json`}</span></code></pre>
    <p>{`19:01`}</p>
    <p>{`Let’s fill in the header comment in index.d.ts`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`// Type definitions for @theme-ui/components 0.2.50`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`// Project: https://github.com/system-ui/theme-ui`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`// Definitions by: Piotr Monwid-Olechnowicz <https://github.com/hasparus>`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`// TypeScript Version: 3.7`}</span></code></pre>
    <p>{`19:10`}</p>
    <p><inlineCode parentName="p">{`theme-ui`}</inlineCode>{` reexports a bunch of components from its `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/master/packages/components/src/index.js"
      }}>{`index.js`}</a>{`, let’s list them here.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Box, Flex, Grid, Button, Link, Text, Heading, Image, Card, Label, Input, Select, Textarea, Radio, Checkbox, Slider, Field, Progress, Donut, Spinner, Avatar, Badge, Close, Alert, Divider, Embed, AspectRati, AspectImag, Containe, NavLin, Message, IconButton, MenuButton`}</span></code></pre>
    <p>{`Quite a lot of them, right?
With a little of multi-cursor karate, I’ve made a stub of the definitions.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Box`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`FC`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Flex`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`FC`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`//...`}</span></span></code></pre>
    <p>{`This isn’t really useful, but I’ll make a commit and push to my fork in case
my computer blows up or anything.`}</p>
    <p>{`19:39`}</p>
    <p>{`I’ve looked a bit in theme-ui repo for issues including TypeScript and found
`}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/issues/121"
      }}>{`this one`}</a>{`. The last comment
is a question about `}<inlineCode parentName="p">{`@theme-ui/components`}</inlineCode>{` from yesterday. I’m not the only
who needs it. Awesome.`}</p>
    <p>{`I’ve added a simple test in `}<inlineCode parentName="p">{`theme-ui__components-tests.tsx`}</inlineCode>{`. Just creating all
elements with no props. I had to modify paths in tsconfig to get the import
working, because there’s a lint rule here prohibiting relative imports.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "json"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`paths`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`"@theme-ui/components"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: [`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk38"
          }}>{`theme-ui__components`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  },`}</span></span></code></pre>
    <p>{`Let’s start from the `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/master/packages/components/src/Box.js"
      }}>{`Box`}</a>{`.
This should be the hardest one.`}</p>
    <p>{`19:48`}</p>
    <p>{`There are some dependencies here.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` styled `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@emotion/styled`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` css, { get } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@styled-system/css`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { createShouldForwardProp } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@styled-system/should-forward-prop`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` space `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@styled-system/space`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` color `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@styled-system/color`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`I have a strong feeling that I’ll need types from them.
I’ll have to add package.json with the ones that have types outside of DT to
my theme-ui`}{`_`}{`_`}{`components directory and add
them to `}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/#i-notice-some-packages-having-a-packagejson-here"
      }}>{`dependencies whitelist`}</a>{`
in types publisher, if they’re not there already.`}</p>
    <p>{`This leaves me with styled-system packages. I’ll add `}<inlineCode parentName="p">{`@styled-system/css`}</inlineCode>{`
to paths and I’ll just ignore the props given by `}<inlineCode parentName="p">{`space`}</inlineCode>{` and `}<inlineCode parentName="p">{`color`}</inlineCode>{` for now.`}</p>
    <p>{`Box gets its props from these 5 functions:`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  base,`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  variant,`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  space,`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  color,`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  sx,`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  props => props.css`}</span></code></pre>
    <p>{`So `}<inlineCode parentName="p">{`sx`}</inlineCode>{` and `}<inlineCode parentName="p">{`css`}</inlineCode>{` props give us a css prop syntax with no JSX pragma.
This gets me to`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` BoxProps {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  css`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Interpolation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  sx`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`SxStyleProp`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`20:21`}</p>
    <p>{`Gotta take a break now. I’ll continue in the morning. Maybe I’ll even post a
half-assed PR so the other guy could continue my work.`}</p>
    <p>{`08:20`}</p>
    <p>{`I’m starting work at 10. I gotta move fast.
`}<inlineCode parentName="p">{`variant`}</inlineCode>{` will be a string, and I’ve just `}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/288692b272b695eda00a47f93339e1e16ee756b1/types/styled-system__css/index.d.ts#L452"
      }}>{`found`}</a>{` the names of space props. Not sure what to do with them yet.
Should I pick them from `}<inlineCode parentName="p">{`AllSystemCSSProperties`}</inlineCode>{`?`}</p>
    <p>{`I’m getting `}<inlineCode parentName="p">{`any`}</inlineCode>{` in `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop properties in tests 😢
Since the dependency on `}<inlineCode parentName="p">{`@styled-system/css`}</inlineCode>{` is aliased and `}<inlineCode parentName="p">{`@styled-system/css`}</inlineCode>{`
depends on `}<inlineCode parentName="p">{`csstype`}</inlineCode>{`, I’m gonna go to `}<inlineCode parentName="p">{`types/styled-system__css`}</inlineCode>{` dir and
run `}<inlineCode parentName="p">{`yarn`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(property) bg`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` string `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` string[] `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` SystemCssProperties `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` CSSPseudoSelectorProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` CSSSelectorObject `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` VariantProperty `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` UseThemeFunction `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (string `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` more `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`undefined`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)[] `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`theme`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`any`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ResponsiveStyleValue`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`undefined`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`--`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`-`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`The background`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`color `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`CSS`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` property sets the background color `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`of`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` an element`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span></span></code></pre>
    <p>{`Beautiful.`}</p>
    <p>{`08:55`}</p>
    <p>{`Okay, I’ve found actual `}<inlineCode parentName="p">{`SpaceProps`}</inlineCode>{` type in `}<inlineCode parentName="p">{`@types/styled-system`}</inlineCode>{`.
I’ve already started building this type, copying JSDocs from `}<inlineCode parentName="p">{`AliasesCSSProperties`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` StyledSystemSpaceProps {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * The `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`margin`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` CSS property sets the margin area on all four sides of an element. It is a shorthand for `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-top\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-right\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-bottom\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`, and `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-left\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * | Chrome | Firefox | Safari |  Edge  |  IE   |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * | :----: | :-----: | :----: | :----: | :---: |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  |  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`12`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`3`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@see`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`https://developer.mozilla.org/docs/Web/CSS/margin`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  m`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`SystemCssProperties`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`m`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`];`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * The `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`margin`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` CSS property sets the margin area on all four sides of an element. It is a shorthand for `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-top\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-right\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-bottom\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`, and `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`\`margin-left\``}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * | Chrome | Firefox | Safari |  Edge  |  IE   |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * | :----: | :-----: | :----: | :----: | :---: |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  |  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`  | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`12`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` | `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk5 mtkb"
          }}>{`3`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`**`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` |`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    * `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@see`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`https://developer.mozilla.org/docs/Web/CSS/margin`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`    */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  margin`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`SystemCssProperties`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`margin`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`];`}</span></span></code></pre>
    <p>{`I’ll save myself the trouble and extend SpaceProps.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { SpaceProps, ColorProps } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`styled-system`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` BoxProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`SpaceProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`ColorProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  variant`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  sx`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`SxStyleProp`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  css`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Interpolation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Box`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`FC`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`Okay, but Box is created with `}<inlineCode parentName="p">{`@emotion/styled`}</inlineCode>{` so it should be `}<inlineCode parentName="p">{`StyledComponent`}</inlineCode></p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` BoxStyleProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`SpaceProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`ColorProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  variant`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  sx`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`SxStyleProp`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  css`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Interpolation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Box`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`StyledComponent`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ComponentProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`div`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxStyleProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  {}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`We can use `}<inlineCode parentName="p">{`withComponent`}</inlineCode>{` to substitute the div inside Box with something else.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`SectionBox`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`Box`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`withComponent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`section`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span></code></pre>
    <p>{`And on top of it, we now support all `}<inlineCode parentName="p">{`div`}</inlineCode>{` props, like `}<inlineCode parentName="p">{`contentEditable`}</inlineCode>{` or `}<inlineCode parentName="p">{`tabIndex`}</inlineCode>{`.`}</p>
    <p>{`We get `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/master/packages/components/src/Flex.js"
      }}>{`Flex`}</a>{` for free.
It’s just a Box with `}<inlineCode parentName="p">{`display: flex`}</inlineCode>{`.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui/blob/master/packages/components/src/Grid.js"
      }}>{`Grid`}</a>{`
has `}<inlineCode parentName="p">{`width`}</inlineCode>{`, `}<inlineCode parentName="p">{`columns`}</inlineCode>{` and `}<inlineCode parentName="p">{`gap`}</inlineCode>{`.`}</p>
    <p>{`The Grid forwards ref to Box, so we have to check what `}<inlineCode parentName="p">{`forwardRef`}</inlineCode>{` returns.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`ForwardRefExoticComponent`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`PropsWithoutRef`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`P`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`&`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`RefAttributes`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>>`}</span></span></code></pre>
    <p>{`Brilliant. Let’s alias it to `}<inlineCode parentName="p">{`ForwardRef<T, P>`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` BoxProps`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Omit`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ComponentProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`div`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>, `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`color`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`css`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BoxStyleProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` GridProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`BoxProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * Minimum width of child elements`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  width`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ResponsiveValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`number`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * 	Number of columns to use for the layout (cannot be used in conjunction with the width prop)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  columns`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ResponsiveValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`number`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   * Space between child elements`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`   */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  gap`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ResponsiveValue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`number`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Grid`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ForwardRef`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`HTMLDivElement`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`GridProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`“Cannot be used in conjunction” suggests a union type, but I’m afraid of `}<a parentName="p" {...{
        "href": "https://github.com/microsoft/TypeScript/issues/34933"
      }}>{`TS2589`}</a>{`
so I’ll pass.`}</p>
    <p>{`09:24`}</p>
    <p>{`It’s getting a bit late already, so I’ll add a bit more and do the PR.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ButtonProps`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Assign`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ComponentPropsWithRef`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`button`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxStyleProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Button`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ForwardRef`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`HTMLButtonElement`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` LinkProps`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Assign`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ComponentPropsWithRef`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`a`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxStyleProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Link`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ForwardRef`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`HTMLAnchorElement`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`LinkProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` TextProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ForwardRef`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`HTMLDivElement`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` HeadingProps`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Assign`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`React`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ComponentPropsWithRef`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`h2`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BoxStyleProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Heading`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ForwardRef`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`HTMLHeadingElement`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`HeadingProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`Continuing from this point should be a bit more pleasant.`}</p>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/pull/41057"
      }}>{`The PR`}</a>{` was merged after a few days 🎉`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      